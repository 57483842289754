.navbar-lang {

	@lang-height: 48px;

	position: absolute;
	z-index: 2000;
	top: @grid-gutter-width;
	left: @grid-gutter-width;


	@media (max-width: @grid-float-breakpoint-max){
		&, .collapsing & {
			.opacity(0);
		}

		.transition(opacity .2s);

		.collapse.in & {
			.opacity(1);
		}
	}

	@media (min-width: @grid-float-breakpoint){
		right: @grid-gutter-width;
		top: calc(100% + @grid-gutter-width);
		left: auto;
	}

	>a {
		font-size: 16px;
		font-weight: 500;
		display: inline-block;
		line-height: @lang-height;
		border-radius: @lang-height / 2;
		.square(@lang-height);
		.text-uppercase;
		.text-center;


		&, &:hover, &:active, &:visited {
			background: @turkiz;
			color: @white;
		}

		+a {
			margin-left: @grid-gutter-width * .75;
		}

		&.active {
			background: @white;
			color: @turkiz;
		}
		@media (max-width: @screen-xs-max){
			font-size: 14px;
			line-height: 32px;
			border-radius: 16px;
			.square(32px);
			+a {
				margin-left: @grid-gutter-width * .5;
			}

		}
	}
}

@logo-margin: -50px;
@logo-xs-width: 130px;
@logo-md-width: 186px;

.slide {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	.opacity(0);
	.transition(all .6s ease-in-out);

	&.active {
		.opacity(1);
	}

	.navbar-logo & {
		background-position: 50% 0;
	}

	#highlight & {
		background-position: 50% @logo-margin;
	}
}

.make-logo(@width: @logo-xs-width, @bg-size: @container-sm){
	margin-left: -@width / 2;
	background-size: @bg-size;
	bottom: -@width - @logo-margin;

	&, img {
		.square(@width);
	}
}

.navbar-logo {
	.make-logo(@logo-xs-width);
	position: absolute;
	left: 50%;
	z-index: 2000;

	>div.hatter{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background-position: 50% 0;
	} 
	>a{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		z-index: 2000;
	}

	@media (min-width: @screen-md-min){
		.make-logo(@logo-md-width, @container-md);
	}

	@media (min-width: @screen-lg-min){
		.make-logo(@logo-md-width, @container-lg);
	}
}

.make-kor(@kor-width) {
	.square(@kor-width);
	border-radius: @kor-width / 2;
	margin-left: -@kor-width / 2;
	bottom: -@kor-width * .4;
	padding: @kor-width * .05;
}

@highlight-height: 288px;

#content {
	background: @white url("@{images-url}bg-content.jpg") 0 @highlight-height + @navbar-height repeat-x;
}

#highlight {
	height: @highlight-height;
	position: relative;
	
	background-repeat: no-repeat;
	background-size: @container-sm;
	margin-bottom: 30px;
	.text-center;

	>div.slider {
	visibility: hidden;
	}

	>div.hatter {
		visibility: visible;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-position: 50% @logo-margin;


	}

	>div.also {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background: url("@{images-url}bg-highlight-bottom.png") 50% 100% no-repeat;
		padding-top: @logo-xs-width * .7;
		height: 100%;
		z-index: 1100;
	}

	svg {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		max-height: 50px;
	}

	h1 {
		.font-light;
		.color-white;
		.text-lowercase;
		font-size: 40px;
		font-style: italic;
		letter-spacing: -2px;
		margin: 0;
	}

	p {
		.color-white;
		.font-light;
		.center-block;
		font-size: 12px;
	}

	.kor {
		.make-kor(180px);
		position: absolute;
		left: 50%;
		.vertical-align;
		.text-center;
		.text-uppercase;
		.font-normal;
		background: @white data-uri("@{datauri-images-url}nyil-le.png") 50% 80% no-repeat;
		background-size: 40px;

		&.highlight-kor {
			margin-bottom: 180px * .4 + 30px;
		}

		span {
			.vertical-align> .vertical-align-middle;
			font-size: 12px;
		}
	}
}

.nav > li > h2 {
	position: relative;
	display: block;
	padding: 10px 12px;
}

.navbar-nav > li > h2 {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 22px;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
}


@media (min-width: @screen-sm-min){
	#highlight {
		p {
			max-width: 80%;
			font-size: 14px;
		}
	}
}

@media (min-width: @screen-md-min){
	@highlight-height: 389px;

	#content {
		background-position: 0 @highlight-height + @navbar-height;
	}

	#highlight {
		height: @highlight-height;
		background-size: @container-md;

		>div {
			padding-top: @logo-md-width * .8;
		}

		&.highlight-kor {
			margin-bottom: 220px * .4 + 30px;
		}

		h1 {
			letter-spacing: -4px;
		}

		.kor {
			.make-kor(220px);

			span {
				font-size: 16px;
			}
		}
	}
}

@media (min-width: @screen-lg-min){
	@highlight-height: 567px;

	.navbar-nav > li > h2 {
		padding-top: 39px;
		padding-bottom: 39px;
	}

	#content {
		background-position: 0 @highlight-height + @navbar-height;
	}

	#highlight {
		height: @highlight-height;
		background-size: @container-lg;

		>div {
			padding-top: @logo-md-width + @logo-margin + 70px;
		}

		h1 {
			font-size: 100px;
		}

		p {
			max-width: 60%;
			font-size: 18px;
		}

		.kor {
			.make-kor(280px);
			background-size: auto;

			span {
				font-size: 20px;
			}
		}
	}
}
