@import '_defaults';
@import 'bootstrap/bootstrap';
@import 'header';
@import 'footer';
@import 'fancybox';
@import 'fancybox-thumbs';
@import '../vendor/smalot-bootstrap-datetimepicker/less/datetimepicker';

body {
  background-image: url('@{images-url}bg-body.png');
  background-repeat: repeat-x;
}

.bg-szurke-gradient {
  #gradient > .vertical(#d3d3d3, #f2f2f2, 0%, 10%);
}

.haromszog {
  @haromszog-width: 56px;

  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: @white;
    color: @turkiz;
    .font-normal;
    .text-center;
    .square(@haromszog-width);
    .rotate(45deg);
    line-height: @haromszog-width;
    font-size: @haromszog-width * 0.8;
    transform-origin: 50% 50%;
    left: 95px;
    top: -@haromszog-width / 2;
    z-index: 100;
  }

  &.plusz:before {
    content: '\00d7';
  }

  &.kozepen:before {
    left: 50%;
    margin-left: -@haromszog-width / 2;
  }
}

section.elso {
  @media (max-width: @screen-sm-max) {
    margin-top: 60px;
  }
}

section.galeria {
  background: url('@{images-url}fooldal-galeria.jpg') 50% 50% no-repeat;
  height: 380px;
  position: relative;
  margin-top: @grid-gutter-width * 2;

  a {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    .square(160px);
    margin-top: -80px;
    margin-left: -80px;
    background-color: @turkiz;
    color: @white;
    border-radius: 80px;
    vertical-align: middle;
    letter-spacing: 4px;
    .text-center;
    .text-uppercase;
    .font-normal;
    .vertical-align;
    .transition(0.2s all);
    .opacity(1);

    span {
      .vertical-align > .vertical-align-middle;
      font-size: 24px;

      &:after {
        content: '+';
        display: block;
        position: absolute;
        bottom: 1em;
        left: 45%;
        font-size: 25px;
      }
    }

    &:hover {
      .scale(0.97);
      .opacity(0.85);
    }
  }
}

.galeriak {
  margin-bottom: 5em;
}

.kepek {
  margin: 5px 0;
  border: 5px solid #dddddd;
  height: auto;
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    max-height: 20vw;
    overflow: hidden;
  }

  @media (min-width: @screen-md-min) {
    height: 80px;
    overflow: hidden;
  }
}

section.virtualis {
  background: url('@{images-url}tour_bg.png') 50% 80% no-repeat;
  height: 380px;
  position: relative;
  margin-top: @grid-gutter-width * 2;
  margin-left: -10px;
  margin-right: -10px;

  a {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    .square(160px);
    margin-top: -80px;
    margin-left: -80px;
    background-color: @turkiz;
    color: @white;
    border-radius: 80px;
    vertical-align: middle;
    letter-spacing: 4px;
    .text-center;
    .text-uppercase;
    .font-normal;
    .vertical-align;
    .transition(0.2s all);
    .opacity(1);

    span {
      .vertical-align > .vertical-align-middle;
      font-size: 23px;
      position: absolute;
      top: 15%;
      left: 4%;
    }

    &:hover {
      .scale(0.97);
      .opacity(0.85);
    }
  }
  > div.also {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@{images-url}bg-highlight-bottom.png') 50% 100% no-repeat;
    padding-top: @logo-xs-width * 0.7;
    height: 100%;
    z-index: 1100;
  }
}

#google-map {
  height: 600px;
  max-height: 85vh;

  > div {
    .bg-szurke-gradient;
  }
}

.page-header {
  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 31px;
    }
  }
}

.programok {
  border-top: 1px solid #4995a1;
  padding-top: 30px;
}
