// TODO: app.less elején importáld

@root-url:				"../../";
@fonts-url:				"@{root-url}fonts/";
@images-url:			"@{root-url}images/";
@datauri-images-url:    "../../../public/images/"; // data-urihoz lesshez képesti relatív útvonal kell

.vertical-align {
	font-size: 0;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle
	}

	.vertical-align-middle {
		vertical-align: middle;
		display: inline-block;
		max-width: 100%
	}
}

.bg-white {
	background-color: @white;
}

.color-white {
	color: @white;
}

.color-turkiz {
	color: @turkiz;
}

.color-gray-lighter {
	color: @gray-lighter;
}

.font-light {
	font-weight: 100;
}

.font-normal {
	font-weight: 300;
}

.font-semibold {
	font-weight: 500;
}

.font-bold {
	font-weight: 700;
}

.text-notransform {
	text-transform: none !important;
}