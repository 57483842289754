footer {
	padding: @grid-gutter-width;

	a+a {
		margin-left: .5em;
	}

	@media (max-width: @screen-xs-max) {
		.col-copyright, .col-links {
			.text-center;
			margin-bottom: @grid-gutter-width;
		}
	}

	@media (min-width: @screen-sm-min) {
		line-height: 111px;

		.col-links {
			.text-right;
		}
	}
}